export const useFoodTagStore = defineStore('food-tag', () => {
  const ALL_TAGS = [
    'is_processed_food',
    'is_gluten_free',
    'is_vegetarian',
    'is_vegan',
    'is_added_sugar_free',
    'is_meat_free',
    'is_pork_free',
    'is_fish_or_seafood_free',
  ];

  return {
    ALL_TAGS,
  };
});
