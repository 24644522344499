export function getNutrientValueFromOpenFoodItem(
  nutrients: Record<string, string>,
  nutrientLabel: string,
): Record<string, number | string> | null {
  if (!nutrients) {
    return null;
  }
  // escape key like 'protein-unit' or 'protein_100g'
  // as we only want key value from 'protein'
  const allNutrientsKeysInOpenFoodItem = Object.keys(nutrients).filter(
    (k) => !k.includes('_') && !k.includes('-'),
  );

  let nectarNutrientKey = null;
  const matchNutrientKey = allNutrientsKeysInOpenFoodItem.find(
    (nutrientKey) => {
      if (nutrientKey[nutrientKey.length - 1] === 's') {
        nutrientKey = nutrientKey.slice(0, -1);
      }
      nectarNutrientKey = nutrientKey;

      return nutrientLabel.toLowerCase().includes(nutrientKey);
    },
  );

  const matchNutrientUnitKey = Object.keys(nutrients)
    .filter((nutrientKey) => nutrientKey.includes(matchNutrientKey))
    .find((k) => k.includes('_unit'));

  return matchNutrientKey
    ? {
        value: parseFloat(nutrients[matchNutrientKey]),
        unit: nutrients[matchNutrientUnitKey],
      }
    : null;
}
